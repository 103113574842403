const style = {
  countryOrigin: {
    wrapperCol: { span: 8 },
  },
  operatorOrigin: {
    wrapperCol: { span: 8 },
  },
  officeOrigin: {
    wrapperCol: { span: 8 },
  },
  countryDestination: {
    wrapperCol: { span: 8 },
  },
  operatorDestination: {
    wrapperCol: { span: 8 },
  },
  officeDestination: {
    wrapperCol: { span: 8 },
  },
  dispatchDate: {
    wrapperCol: { span: 12 },
  },
  dispatchYear:{
    wrapperCol: { span: 6 },
  },
  dispatchNumber: {
    wrapperCol: { span: 6 },
  },
  dispatchType: {
    wrapperCol: { span: 6 },
  },
  documentQuery: {
    wrapperCol: { span: 8 },
  },
  category: {
    wrapperCol: { span: 14 },
  }
}

export default style;
