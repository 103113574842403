import React from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "antd";
import SearchCard from "../../components/shared/Cards/SearchCard/SearchCard";

const Home = (props) => {
  const { t } = props;

  return (
    <div className="home">
      <div className="overlay-bg">
        <img src="/images/importacion_exportacion.svg" alt="banner icon"/>
        <span className="overlay-bg__title">
          {t("pages_Home.titleDocument")}
        </span>
        <span className="overlay-bg__description">
          {t("pages_Home.description")}
        </span>
      </div>

      <div className="grid-cards">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <SearchCard
              title={t("pages_Home.officesCard.title")}
              iconUrl="/images/search_offices.svg"
              description={t("pages_Home.officesCard.description")}
              route="/offices"
              textButton={t("pages_Home.officesCard.searchButton")}
            />
          </Col>
          <Col span={12}>
            <SearchCard
              title={t("pages_Home.expeditionsCard.title")}
              iconUrl="/images/search_expeditions.svg"
              description={t("pages_Home.expeditionsCard.description")}
              route="/expeditions"
              textButton={t("pages_Home.expeditionsCard.searchButton")}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(Home);
