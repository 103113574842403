import React, {useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Form as AntdForm,
    Row,
    Col,
    Input,
    Select,
    DatePicker,
    Button,
    Collapse,
    Icon,
    Divider,
} from "antd";
import "./Form.scss";
import style from "./Form.style";
import constants from "../../../../utils/constants";
import { storeArq } from 'correos-arq-utils';

const Form = (props) => {
    const { t } = props;
    const { getFieldDecorator } = props.form;

    const [destinationCarrierCode, setDestinationCarrierCode] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const criteria = {
                    ...values,
                    departureDate: values.departureDate?.format()
                };

                const query = {
                    offset: 1,
                    count: constants.itemsPerPage,
                    country: values.countryOrigin,
                    searchType: constants.expeditionSearch,
                    criteria
                };

                /*const fakeQuery = {
                  offset: 1,
                  count: constants.itemsPerPage,
                  country: "PER",
                  searchType: "expedicion",
                  criteria: {
                    countryOrigin: "ES",
                    operatorOrigin: "ESA",
                    officeOrigin: "MADA",
                    countryDestination: "US",
                    operatorDestination: "ESA",
                    officeDestination: "MADC",
                    airportCode: "ORD",
                    expeditionCode: "ESM19000327F",
                    transportNumber: "AA0002",
                    documentQuery: "CN31",
                    departureDate: "2020-03-09T10:25:25+01:00",
                    operationType: "EXP",
                    flightStatus:"Abierto",
                    expeditionDate: "2020-03-08T10:25:25+01:00"
                  }
                };*/

                props.onSubmit(query);
            }
        });
    };

    useEffect(() => {
        setDestinationCarrierCode(storeArq.data.auth.userInfo.rolReal[0].substring(storeArq.data.auth.userInfo.rolReal[0].length-3));
    }, []);

    return (
        <div className="form">
            <Row justify="center">
                <Col className="form__title" lg={25}>
                    {t("pages_expeditions.form.title")}
                </Col>
            </Row>
            <AntdForm onSubmit={handleSubmit} layout="vertical">
                <div className="form--shadow">
                    <Row className="form__container">
                        <Row>
                            <Col className="form__section--title" lg={9}>
                                {t("pages_expeditions.form.sectionOne")}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <p className="o-txt">
                                    {t("pages_expeditions.form.descriptionOne")}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <AntdForm.Item
                                    {...style.countryOrigin}
                                    label={t("pages_expeditions.form.countryOrigin.label")}
                                >
                                    {getFieldDecorator("countryOrigin", {
                                        initialValue: "ES",
                                        rules: [
                                            {
                                                required: false,
                                                message: t("pages_expeditions.form.countryOrigin.validation"),
                                            }
                                        ],
                                    })(
                                        <Input type="text"  disabled
                                            placeholder={t(
                                                "pages_offices.form.countryOrigin.placeholder"
                                            )}
                                        />
                                    )}
                                </AntdForm.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <AntdForm.Item
                                    {...style.operatorOrigin}
                                    label={t("pages_expeditions.form.operatorOrigin.label")}
                                >
                                    {getFieldDecorator("operatorOrigin", {
                                        initialValue: "ESA",
                                        rules: [{ required: false }],
                                    })(
                                        <Input type="text" readOnly disabled
                                            placeholder={t(
                                                "pages_offices.form.countryOrigin.placeholder"
                                            )}
                                        />
                                    )}
                                </AntdForm.Item>
                            </Col>
                        </Row>
                        <Row>
                            <AntdForm.Item
                                {...style.officeOrigin}
                                label={t("pages_expeditions.form.officeOrigin.label")}
                                hasFeedback
                            >
                                {getFieldDecorator("officeOrigin", {
                                    rules: [
                                        {
                                            required: true,
                                            message: t(
                                                "pages_expeditions.form.officeOrigin.validation"
                                            ),
                                        },
                                    ],
                                })(
                                    <Select
                                        placeholder={t(
                                            "pages_expeditions.form.officeOrigin.placeholder"
                                        )}
                                    >
                                        {props?.sourceOffices?.map((office, index) => (
                                            <Select.Option key={index} value={office.value}>
                                                {office.description}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </AntdForm.Item>
                        </Row>
                        <Row>
                            <Col className="form__section form__section--title" lg={30}>
                                {t("pages_expeditions.form.sectionTwo")}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <p className="o-txt">
                                    {t("pages_expeditions.form.descriptionTwo")}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <AntdForm.Item
                                    {...style.countryDestination}
                                    label={t("pages_expeditions.form.countryDestination.label")}
                                >
                                    {getFieldDecorator("countryDestination", {
                                        rules: [{ required: false }],
                                    })(
                                        <Input
                                            placeholder={t(
                                                "pages_expeditions.form.countryDestination.placeholder"
                                            )}
                                        />
                                    )}
                                </AntdForm.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <AntdForm.Item
                                    {...style.operatorDestination}
                                    label={t("pages_expeditions.form.operatorDestination.label")}
                                >
                                    {getFieldDecorator("operatorDestination", {
                                        initialValue: destinationCarrierCode,
                                        rules: [{ required: false }],
                                    })(
                                        <Input readOnly disabled
                                            placeholder={t(
                                                "pages_expeditions.form.operatorDestination.placeholder"
                                            )}
                                        />
                                    )}
                                </AntdForm.Item>
                            </Col>
                        </Row>
                        <Row>
                            <AntdForm.Item
                                {...style.officeDestination}
                                label={t("pages_expeditions.form.officeDestination.label")}
                                hasFeedback
                            >
                                {getFieldDecorator("officeDestination", {
                                    rules: [{ required: false }],
                                })(
                                  <Input
                                    placeholder={t(
                                        "pages_expeditions.form.officeDestination.placeholder"
                                    )}
                                  />
                                )}
                            </AntdForm.Item>
                        </Row>
                        <Row>
                            <Col className="form__section form__section--title" lg={30}>
                                {t("pages_expeditions.form.sectionThree")}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <p className="o-txt">
                                    {t("pages_expeditions.form.descriptionThree")}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <AntdForm.Item
                                    {...style.departureDate}
                                    label={t("pages_expeditions.form.departureDate.label")}
                                >
                                    {getFieldDecorator("departureDate", {
                                        rules: [
                                            {
                                                type: "object",
                                                required: true,
                                                message: t(
                                                    "pages_expeditions.form.departureDate.validation"
                                                ),
                                            },
                                        ],
                                    })(
                                        <DatePicker
                                            placeholder={t(
                                                "pages_expeditions.form.departureDate.placeholder"
                                            )}
                                            format="MM/DD/YYYY"
                                        />
                                    )}
                                </AntdForm.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Divider />
                            <Col className="form__disclaimer" lg={8}>
                                <Icon type="info-circle" />
                                {`  ${t("pages_expeditions.form.requiredDisclaimer")}`}
                            </Col>
                            <Divider />
                        </Row>
                    </Row>
                    <Collapse defaultActiveKey={["1"]}>
                        <Collapse.Panel
                            header={t("pages_expeditions.form.accordionText")}
                            key="1"
                        >
                            <Row>
                                <Col className="form__section--title" lg={30}>
                                    {t("pages_expeditions.form.sectionFour")}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={30}>
                                    <p className="o-txt">
                                        {t("pages_expeditions.form.descriptionFour")}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={30}>
                                    <AntdForm.Item
                                        {...style.airportCode}
                                        label={t("pages_expeditions.form.airportCode.label")}
                                    >
                                        {getFieldDecorator("airportCode", {
                                            rules: [{ required: false }],
                                        })(
                                            <Input
                                                placeholder={t(
                                                    "pages_expeditions.form.airportCode.placeholder"
                                                )}
                                            />
                                        )}
                                    </AntdForm.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={30}>
                                    <AntdForm.Item
                                        {...style.expeditionCode}
                                        label={t("pages_expeditions.form.expeditionCode.label")}
                                    >
                                        {getFieldDecorator("expeditionCode", {
                                            rules: [{ required: false }],
                                        })(
                                            <Input
                                                placeholder={t(
                                                    "pages_expeditions.form.expeditionCode.placeholder"
                                                )}
                                            />
                                        )}
                                    </AntdForm.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={30}>
                                    <AntdForm.Item
                                        {...style.transportNumber}
                                        label={t("pages_expeditions.form.transportNumber.label")}
                                    >
                                        {getFieldDecorator("transportNumber", {
                                            rules: [{ required: false }],
                                        })(
                                            <Input
                                                placeholder={t(
                                                    "pages_expeditions.form.transportNumber.placeholder"
                                                )}
                                            />
                                        )}
                                    </AntdForm.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="form__section form__section--title" lg={30}>
                                    {t("pages_expeditions.form.sectionFive")}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={30}>
                                    <p className="o-txt">
                                        {t("pages_expeditions.form.descriptionFive")}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <AntdForm.Item
                                    {...style.documentQuery}
                                    label={t("pages_expeditions.form.documentQuery.label")}
                                    hasFeedback
                                >
                                    {getFieldDecorator("documentQuery", {
                                        rules: [{ required: false }],
                                    })(
                                        <Select
                                            placeholder={t(
                                                "pages_expeditions.form.documentQuery.placeholder"
                                            )}
                                        >
                                            {props?.documents?.map((document, index) => (
                                                <Select.Option key={index} value={document.value}>
                                                    {document.description}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </AntdForm.Item>
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <Row className="form__submit">
                    <Col span={5}>
                        <AntdForm.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: "50 %" }}
                            >
                                {t("pages_expeditions.form.submit")}
                            </Button>
                        </AntdForm.Item>
                    </Col>
                </Row>
            </AntdForm>
        </div>
    );
};

Form.propTypes = {
    documents: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    sourceOffices: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    targetOffices: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    onSubmit: PropTypes.func,
};

export default AntdForm.create()(Form);
