import { 
  Home,
  Offices,
  Expeditions
} from '../pages';

const pathRoutes = [
  {
    path: '/',
    component: Home,
    label: 'router_routes.home',
    public: true
  },
  {
    path: '/offices',
    component: Offices,
    label: 'router_routes.offices',
    public: true
  },
  {
    path: '/expeditions',
    component: Expeditions,
    label: 'router_routes.expeditions',
    public: true
  }
];

export default pathRoutes;
