/* eslint-disable */
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Row, Col, Layout, ConfigProvider, Menu, Icon } from 'antd';
import Spanish from 'antd/lib/locale/es_ES';
import { Header } from 'correos-arq-header';
import { MLateral, BtnBurguer, MyRoutes } from 'correos-arq-mlateral';
import { MHorizontal } from 'correos-arq-mhorizontal';
import { BreadCrumb } from 'correos-arq-breadcrumb';
import { PrivateRoute, Login, RedirectLogin } from 'correos-arq-auth';
import { storeArq } from 'correos-arq-utils';
import pathRoutes from './router/routes';
import rolesWeb from './router/rolesweb';
import rolesWebCId from "./router/roleswebCId";
import i18n from './i18nConfig/i18n';
import { withTranslation } from "react-i18next";

// Declararación del Content del Layout de antd
const { Content } = Layout;

const createHistory = require('history').createBrowserHistory;

storeArq.load();
class App extends Component {

    constructor(props) {
        super(props);
        require('dotenv').config();
        this.state = {
            showMenu: true, //Inicializa con el menu lateral abierto
            auth: storeArq.data.auth,
            areWeHome: true, //Indica si se esta en "/" para renderizar un estilo u otro
            reload: false, //Recargar app para actualizar submenus
            setInfo: false, //Indica cuando se pulsa el boton hamburguesa
        };
    }

    setInfoBtn = (info) => {
        const { showMenu } = this.state;
        showMenu && this.setState({ setInfo: info, showMenu: false })
        !showMenu && this.setState({ setInfo: info, showMenu: true })
    }

    UNSAFE_componentWillMount = () => {
        RedirectLogin();
      };


    clickHome = key => {
        this.setState({ areWeHome: key });
    };
    /* *
     * Muestra el menú o lo oculta
     * según el estado rederizado al hacer click al botón
     * */
    clickMenu = () => {
        this.setState({ showMenu: !this.state.showMenu });
    };

    clickMenuH = () => {
        const { showMenu } = this.state;
        document.getElementById('breadcrumb').classList.remove('breadcrumb--black');
        document.getElementById('breadcrumb').classList.add('breadcrumb--white');
        !showMenu && this.setState({ showMenu: true })
    }

    /* *
     * Se usa en migasDePan para lanzar el render de la aplicacion por cambio de url
     * cambiando los submenus
     * */
    reloadApp = () => {
        this.setState({ reload: !this.state.reload });
    };

    render() {
        const { t } = this.props;
        const { showMenu } = this.state;
        storeArq.load();

        let subMenu = this.state.auth.userInfo.subMenu || {
            childs: [],
            parent: '',
        };
        let menuLateral = '';
        let migasDePan = '';
        let menuPPal = '';
        let menuLatBoton = null;
        let newPathRoutes = MyRoutes.getNewPathRoutes(pathRoutes);

        subMenu = MyRoutes.getSubMenu(pathRoutes, subMenu);

        //Comprobar si hay submenus para renderizar el boton de menu lateral
        subMenu.childs.length > 0
        ? (menuLatBoton = (
        <BtnBurguer showMenu={showMenu} setInfoBtn={this.setInfoBtn} />
        ))
        : (menuLatBoton = null);

        if (this.state.auth.idToken === '' || window.location.pathname === '/login') {
            menuPPal = <div className="MHorizontal" />;
        } else {
            menuPPal = (
                <MHorizontal
                    items={pathRoutes}
                    pathRoutes={pathRoutes}
                    clickMenu={this.clickMenuH}
                    areWeHome={this.state.areWeHome}
                    clickHome={this.clickHome}
                />
            );

            if (showMenu) {
                menuLateral = (
                    <Row >
                        <Col lg={4} style={{ width: 'auto' }} >
                            <MLateral longItem="20" items={subMenu} reloadApp={this.reloadApp} />
                        </Col>
                    </Row>
                );
                //Necesario pasar pathname como props aunque no se utilice
                //Sirve para renderizar otra vez las migas cuando se cambia la url
                migasDePan = (
                    <Row>
                        <Col span={1} className="ContainBtn">{menuLatBoton}</Col>
                        <BreadCrumb
                            clickHome={this.clickHome}
                            path={window.location.pathname}
                            clickMenu={showMenu && this.clickMenu}
                            routes={newPathRoutes.map(item => ({
                                path: item.path,
                                label: item.label,
                            }))}
                            reloadApp={this.reloadApp}
                        />
                    </Row>
                );
            } else {
                menuLateral = (
                    <Row >
                        <Col span={1} >{menuLatBoton}</Col>
                        <BreadCrumb
                            clickHome={this.clickHome}
                            path={window.location.pathname}
                            clickMenu={showMenu && this.clickMenu}
                            routes={newPathRoutes}
                            reloadApp={this.reloadApp}
                        />
                    </Row>
                );
            }
        }
        return (
                <ConfigProvider locale={Spanish}>
                    <Router>
                        <div className="App">
                            <Layout className="layout">
                                <Header
                                    clickHome={this.clickHome}
                                    clickMenu={showMenu && this.clickMenu}
                                    i18n={i18n}
                                />
                                <Layout className="layout">{menuPPal}</Layout>
                                <Layout className="layout">
                                    <Row>
                                        <Col lg={32}>
                                            <Layout className="layout">
                                                {menuLateral}
                                                <Layout className="layout">
                                                    <div id="breadcrumb" className={`breadcrumb ${window.location.pathname === '/' ? 'breadcrumb--white' : 'breadcrumb--black'}`}>
                                                        {migasDePan}
                                                    </div>
                                                    <Content
                                                        className={showMenu === true ? "ContentSpace" : "Contenido"}
                                                        style={null}
                                                    >
                                                        <Switch>
                                                            {newPathRoutes.map(({ component, path }) => (
                                                                <PrivateRoute
                                                                    exact
                                                                    key={path}
                                                                    path={path}
                                                                    component={component}
                                                                    auth={this.state.auth}
                                                                />
                                                            ))}
                                                            <Route
                                                                path="/login"
                                                                render={(props) => (
                                                                    <Login
                                                                        rolesWeb={rolesWeb}
                                                                        rolesWebCId={rolesWebCId}
                                                                        i18n={i18n}
                                                                        tipoIntegracion={
                                                                            window._env_.REACT_APP_AGORA_TIPO_INTEGRACION
                                                                        }
                                                                        {...props}
                                                                    />
                                                                )}
                                                            />
                                                        </Switch>
                                                    </Content>
                                                </Layout>
                                            </Layout>
                                        </Col>
                                        <Col span={1} />
                                    </Row>
                                </Layout>
                            </Layout>
                        </div>
                    </Router>
                </ConfigProvider>
        );
    }
}

export default withTranslation()(App);
