import React from "react";
import PropTypes from "prop-types";
import { 
  Table, 
  Row, 
  Col, 
  Button, 
  Empty 
} from "antd";
import FilterCard from "../../../../components/shared/Cards/FilterCard/FilterCard";
import columns from "./Results.columns";

const Results = (props) => {
  const { t } = props;
  
  return (
    <>
      <Row>  
        <Col className="results__title" lg={19}>
          {`${t("pages_expeditions.results.header.title")} (${props.totalItems})`}
        </Col>
        <Col lg={5}>
          <Button 
            className="ant-btn-link" 
            type="link" 
            icon="search"
            block
            onClick={props.onNewSearch}
          >
            {t("pages_expeditions.results.header.search")}
          </Button>
        </Col>
      </Row>

      <div className="results__filters">
        <FilterCard 
          items={props.filters} 
          header={t("pages_expeditions.results.header.filter")}
        />
      </div>

      {props.totalItems === 0 
        && !props.isLoading 
          ? (<Empty description={<span>{t("pages_expeditions.results.noResults")}</span>} />)
          : (<Table
              rowKey="internalId"
              columns={columns(t, props.onDownload)}
              dataSource={props.items}
              bordered
              loading={props.isLoading}
              pagination={false}
            />)
      }
    </>
  );
};

Results.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      accessLevel: PropTypes.string,
      airportCode: PropTypes.string,
      contentFileName: PropTypes.string,
      contentMimeType: PropTypes.string,
      countryOrigin: PropTypes.string,
      departureDate: PropTypes.string,
      documentId: PropTypes.string,
      documentQuery: PropTypes.string,
      documentSubType: PropTypes.string,
      documentType: PropTypes.string,
      expeditionCode: PropTypes.string,
      expeditionDate: PropTypes.string,
      flightStatus: PropTypes.string,
      internalId: PropTypes.string,
      locked: PropTypes.bool,
      name: PropTypes.string,
      officeDestination: PropTypes.string,
      officeOrigin: PropTypes.string,
      operationtype: PropTypes.string,
      operatorDestination: PropTypes.string,
      operatorOrigin: PropTypes.string,
      responsibleUnit: PropTypes.string,
      state: PropTypes.string,
      transportNumber: PropTypes.string,
      version: PropTypes.string,
    })
  ),
  onDownload: PropTypes.func,
  onNewSearch: PropTypes.func,
  totalItems: PropTypes.number,
  isLoading: PropTypes.bool
};

export default Results;
