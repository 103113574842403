export function savePdf(binary, name) {
  const blob = new Blob([binary], {type: "application/pdf"});
  const isIE = false || !!document.documentMode;
  const fileName = `${name}.pdf`
  if (isIE) {
      window.navigator.msSaveBlob(blob, fileName);
  } else {
      const url = window.URL || window.webkitURL;
      const link = url.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", fileName);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  }
}