import React, { useEffect, useReducer } from "react";
import { withTranslation } from "react-i18next";
import Form from "./components/form/Form";
import Results from "./components/results/Results";
import {
    searchReducer,
    initialState,
    searchActionKeys,
    searchActions,
    masterdataKeys,
} from "../../hooks/search";
import { masterdataTypes } from "../../models/search";
import {
    searchMasterData,
    searchDocuments,
    downloadContent,
} from "../../Services/searchService";
import { Skeleton, Pagination } from "antd";
import { savePdf } from '../../utils/helpers';
import constants from "../../utils/constants";
import { englishFormat } from "../../utils/dateFormatters";
import "./Expeditions.scss";

/**
 * @typedef {import('../../hooks/search.js').SearchExpeditionDTO} SearchExpeditionDTO
 */

const Expeditions = (props) => {
    const { t } = props;
    const [state, dispatch] = useReducer(searchReducer, initialState);

    const newSearch = () => {
        dispatch(searchActions[searchActionKeys.newSearch]());
    };

    /**
     * @param {SearchExpeditionDTO} query
     */
    const onSearch = (query) => {
        dispatch(searchActions[searchActionKeys.searchDocuments](query));

        searchDocuments(query)
            .then((result) => {
                dispatch(
                    searchActions[searchActionKeys.searchDocumentsSuccess](result.data)
                );
            })
            .catch((error) => {
                dispatch(searchActions[searchActionKeys.searchDocumentsFailure](error));
            });
    };

    /**
     * @param {string} documentId - Document identifier returned by the search
     * @param {Object.<string, number>} params - Query params
     */
    const onDownload = (documentId, params) => {
        dispatch(searchActions[searchActionKeys.downloadContent]());

        downloadContent(documentId, params)
            .then((result) => {
                savePdf(result.data, params.name);
                dispatch(searchActions[searchActionKeys.downloadContentSuccess]());
            })
            .catch((error) => {
                dispatch(searchActions[searchActionKeys.downloadContentFailure](error));
            });
    };

    /**
     * @param {nume} page - current page number
     */
    const onPageChange = (page) => {
        
        const customOffset = page === 1
            ? 1
            : 1 + (constants.itemsPerPage * (page - 1));

        const query = {
            ...state.query,
            offset: customOffset,
        };

        onSearch(query);
    };

    const setCustomStyle = () => {
        document.getElementById('breadcrumb').classList.remove('breadcrumb--white');
        document.getElementById('breadcrumb').classList.add('breadcrumb--black');
    }

    const getCurrentFilters = () => {
        return [
            {
                title: t("pages_expeditions.filters.origin.label"),
                fields: [
                    {
                        title: t("pages_expeditions.filters.origin.children.countryOrigin"),
                        value: state.query?.criteria?.countryOrigin || ""
                    },
                    {
                        title: t("pages_expeditions.filters.origin.children.operatorOrigin"),
                        value: state.query?.criteria?.operatorOrigin || ""
                    },
                    {
                        title: t("pages_expeditions.filters.origin.children.officeOrigin"),
                        value: state.query?.criteria?.officeOrigin || ""
                    },
                ],
            },
            {
                title: t("pages_expeditions.filters.destination.label"),
                fields: [
                    {
                        title: t(
                            "pages_expeditions.filters.destination.children.countryDestination"
                        ),
                        value: state.query?.criteria?.countryDestination || ""
                    },
                    {
                        title: t(
                            "pages_expeditions.filters.destination.children.operatorDestination"
                        ),
                        value: state.query?.criteria?.operatorDestination || ""
                    },
                    {
                        title: t(
                            "pages_expeditions.filters.destination.children.officeDestination"
                        ),
                        value: state.query?.criteria?.officeDestination || ""
                    },
                ],
            },
            {
                title: t("pages_expeditions.filters.others.label"),
                fields: [
                    {
                        title: t("pages_expeditions.filters.others.children.airportCode"),
                        value: state.query?.criteria?.airportCode || ""
                    },
                    {
                        title: t("pages_expeditions.filters.others.children.expeditionCode"),
                        value: state.query?.criteria?.expeditionCode || ""
                    },
                    {
                        title: t("pages_expeditions.filters.others.children.flightStatus"),
                        value: state.query?.criteria?.flightStatus || ""
                    },
                    {
                        title: t("pages_expeditions.filters.others.children.transportNumber"),
                        value: state.query?.criteria?.transportNumber || ""
                    },
                    {
                        title: t("pages_expeditions.filters.others.children.departureDate"),
                        value: state.query?.criteria?.departureDate?.length
                            ? englishFormat(new Date(state.query?.criteria?.departureDate))
                            : ""
                    },
                    {
                        title: t("pages_expeditions.filters.others.children.expeditionDate"),
                        value: state.query?.criteria?.expeditionDate?.length
                            ? englishFormat(new Date(state.query?.criteria?.expeditionDate))
                            : ""
                    }
                ],
            },
        ];
    };

    /**
     * Load all the form masterdata
     */
    useEffect(() => {
        setCustomStyle();
        dispatch(searchActions[searchActionKeys.searchMasterData]());

        const promises = [
            searchMasterData(masterdataTypes.expeditionDocument),
            searchMasterData(masterdataTypes.sourceOffice),
            searchMasterData(masterdataTypes.targetOffice),
        ];

        Promise.all(promises)
            .then((values) => {
                const response = {
                    [masterdataKeys.expeditionDocuments]: values[0].data?.results || [],
                    [masterdataKeys.sourceOffices]: values[1].data?.results || [],
                    [masterdataKeys.targetOffices]: values[2].data?.results || [],
                };

                dispatch(
                    searchActions[searchActionKeys.searchMasterDataSuccess](response)
                );
            })
            .catch((error) => {
                dispatch(
                    searchActions[searchActionKeys.searchMasterDataFailure](error)
                );
            });
    }, []);

    return (
        <>
            {!state.showResults ? (
                <Skeleton loading={state.isLoading}>
                    <Form
                        {...props}
                        documents={state[masterdataKeys.expeditionDocuments]}
                        sourceOffices={state[masterdataKeys.sourceOffices]}
                        targetOffices={state[masterdataKeys.targetOffices]}
                        onSubmit={onSearch}
                    />
                </Skeleton>
            ) : (
                <>
                    <div className="results">
                        <Results
                            {...props}
                            items={state.items}
                            onDownload={onDownload}
                            onNewSearch={newSearch}
                            filters={getCurrentFilters()}
                            totalItems={state.pagination?.totalItems}
                            isLoading={state.isLoading}
                        />
                    </div>

                    {state.pagination?.totalItems > 0 &&
                        (<div className="pagination">
                            <Pagination
                                onChange={onPageChange}
                                pageSize={constants.itemsPerPage}
                                total={state.pagination?.totalItems}
                            />
                        </div>)
                    }
                </>
            )}
        </>
    );
};

export default withTranslation()(Expeditions);
