const style = {
  countryOrigin: {
    wrapperCol: { span: 8 },
  },
  operatorOrigin: {
    wrapperCol: { span: 8 },
  },
  officeOrigin: {
    wrapperCol: { span: 8 },
  },
  countryDestination: {
    wrapperCol: { span: 8 },
  },
  operatorDestination: {
    wrapperCol: { span: 8 },
  },
  officeDestination: {
    wrapperCol: { span: 8 },
  },
  departureDate: {
    wrapperCol: { span: 12 },
  },
  airportCode:{
    wrapperCol: { span: 6 },
  },
  expeditionCode: {
    wrapperCol: { span: 6 },
  },
  transportNumber: {
    wrapperCol: { span: 6 },
  },
  documentQuery: {
    wrapperCol: { span: 8 },
  },
}

export default style;
