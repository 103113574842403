import React from "react";
import { englishFormat } from "../../../../utils/dateFormatters";

const columns = (t, onDownload) => {
  return [
    {
      title: "",
      render: (text, row, index) => {
        return (
          <img
            className="item-download"
            src="/images/ico_download.svg"
            alt="download icon"
            onClick={() => {
              const documentId = row["documentId"];
              const params = {
                documentSubType: row["documentSubType"],
                state: row["state"],
                responsibleUnit: row["responsibleUnit"],
                accessLevel: row["accessLevel"],
                name: row["name"],
              };
              onDownload(documentId, params);
            }}
          />
        );
      },
    },
    {
      title: "Id",
      dataIndex: "documentId",
      key: "documentId",
    },
    {
      title: t("pages_offices.results.table.origin.label"),
      children: [
        {
          title: t(
            "pages_offices.results.table.origin.children.countryOrigin"
          ),
          dataIndex: "countryOrigin",
          key: "countryOrigin",
        },
        {
          title: t(
            "pages_offices.results.table.origin.children.operatorOrigin"
          ),
          dataIndex: "operatorOrigin",
          key: "operatorOrigin",
        },
        {
          title: t("pages_offices.results.table.origin.children.officeOrigin"),
          dataIndex: "officeOrigin",
          key: "officeOrigin",
        },
      ],
    },
    {
      title: t("pages_offices.results.table.destination.label"),
      children: [
        {
          title: t(
            "pages_offices.results.table.destination.children.countryDestination"
          ),
          dataIndex: "countryDestination",
          key: "countryDestination",
        },
        {
          title: t(
            "pages_offices.results.table.destination.children.operatorDestination"
          ),
          dataIndex: "operatorDestination",
          key: "operatorDestination",
        },
        {
          title: t(
            "pages_offices.results.table.destination.children.officeDestination"
          ),
          dataIndex: "officeDestination",
          key: "officeDestination",
        },
      ],
    },
    {
      title: t("pages_offices.results.table.dispatch.label"),
      children: [
        {
          title: t(
            "pages_offices.results.table.dispatch.children.dispatchDate"
          ),
          dataIndex: "dispatchDate",
          key: "dispatchDate",
          render: (text, row, index) => {
            return text?.length ? englishFormat(new Date(text)) : "";
          },
        },
        {
          title: t(
            "pages_offices.results.table.dispatch.children.dispatchYear"
          ),
          dataIndex: "dispatchYear",
          key: "dispatchYear",
        },
        {
          title: t(
            "pages_offices.results.table.dispatch.children.dispatchNumber"
          ),
          dataIndex: "dispatchNumber",
          key: "dispatchNumber",
        },
      ],
    },
    {
      title: t("pages_offices.results.table.category.label"),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("pages_offices.results.table.documentQuery.label"),
      dataIndex: "documentQuery",
      key: "documentQuery",
    },
  ];
};

export default columns;
