const rolesWeb = [
    {
        rol: 'ACCESO_PRE_AD_ADMINISTRADOR',
        accion: [
            { path: '/', sub: null },
            { path: '/offices', sub: null },
            { path: '/expeditions', sub: null }],
        label: 'Administrador',
    },
    {
        rol: 'AGORA-EXP-DEUTSCHE',
        accion: [
            { path: '/', sub: null },
            { path: '/offices', sub: null },
            { path: '/expeditions', sub: null }],
        label: 'Exp Deutsche',
    },
    {
        rol: 'AGORA-EXP-LAPOSTE-FRANCE',
        accion: [
            { path: '/', sub: null },
            { path: '/offices', sub: null },
            { path: '/expeditions', sub: null }],
        label: 'Exp Laposte France',
    },
    {
        rol: 'AGORA-EXP-TNT-POST',
        accion: [
            { path: '/', sub: null },
            { path: '/offices', sub: null },
            { path: '/expeditions', sub: null }],
        label: 'Exp Tnt Post',
    },
    {
        rol: 'AGORA-EXP-ROYALMAIL-GREAT-BRITAIN',
        accion: [
            { path: '/', sub: null },
            { path: '/offices', sub: null },
            { path: '/expeditions', sub: null }],
        label: 'Exp Royalmail Great Britain',
    },
    {
        rol: 'AGORA-EXP-POST-BELGIUM',
        accion: [
            { path: '/', sub: null },
            { path: '/offices', sub: null },
            { path: '/expeditions', sub: null }],
        label: 'Exp Post Belgium',
    },
    { 
        rol: 'FSGD_PRO_US_EXPORTBEA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Post Belgium', 
    }, 
    { 
        rol: 'FSGD_PRO_US_EXPORTDEA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Deutsche', 
    },
    { 
        rol: 'FSGD_PRO_US_EXPORTFRA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Laposte France', 
    }, 
    { 
        rol: 'FSGD_PRO_US_EXPORTGBA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Royalmail Great Britain', 
    }, 
    { 
        rol: 'FSGD_PRO_US_EXPORTNLA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Tnt Post', 
    }, 
    { 
        rol: 'FSGD_PRE_US_EXPORTBEA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Post Belgium', 
    }, 
    { 
        rol: 'FSGD_PRE_US_EXPORTDEA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Deutsche', 
    }, 
    { 
        rol: 'FSGD_PRE_US_EXPORTFRA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Laposte France', 
    }, 
    { 
        rol: 'FSGD_PRE_US_EXPORTGBA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Royalmail Great Britain', 
    }, 
    { 
        rol: 'FSGD_PRE_US_EXPORTNLA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Tnt Post', 
    }, 
    { 
        rol: 'FSGD_DES_US_EXPORTBEA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Post Belgium', 
    }, 
    { 
        rol: 'FSGD_DES_US_EXPORTDEA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Deutsche', 
    }, 
    { 
        rol: 'FSGD_DES_US_EXPORTFRA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Laposte France', 
    }, 
    { 
        rol: 'FSGD_DES_US_EXPORTGBA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Royalmail Great Britain', 
    }, 
    { 
        rol: 'FSGD_DES_US_EXPORTNLA', 
        accion: [ 
            { path: '/', sub: null }, 
            { path: '/offices', sub: null }, 
            { path: '/expeditions', sub: null }], 
        label: 'Exp Tnt Post', 
    }
];
export default rolesWeb;
