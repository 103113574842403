/*eslint-env node*/
export const serviceKeys = {
	searchDocuments: 'searchDocuments',
	searchMasterData: 'searchMasterData',
	downloadContent: 'downloadContent'
};

export const serviceLocator = {
    [serviceKeys.searchDocuments]: {
        path: '/digital-services/documentmanager/api/v1/documents'
    },
    [serviceKeys.searchMasterData]: {
        path: '/digital-services/documentmanager/api/v1/masterdata'
    },
    [serviceKeys.downloadContent]: {
        path: '/digital-services/documentmanager/api/v1/documents/{documentId}/content'
    }
};

export const credencialesAPI = {
    clientId: window._env_.REACT_APP_CLIENT_ID_API,
    clientSecret: window._env_.REACT_APP_CLIENT_SECRET_API
};

