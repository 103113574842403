import React from "react";
import { englishFormat } from "../../../../utils/dateFormatters";

const columns = (t, onDownload) => {
  return [
    {
      title: "",
      render: (text, row, index) => {
        return (
          <img
            className="item-download"
            src="/images/ico_download.svg"
            alt="download icon"
            onClick={() => {
              const documentId = row["documentId"];
              const params = {
                documentSubType: row["documentSubType"],
                state: row["state"],
                responsibleUnit: row["responsibleUnit"],
                accessLevel: row["accessLevel"],
                name: row["name"],
              };
              onDownload(documentId, params);
            }}
          />
        );
      },
      width: 40,
      fixed: "left",
    },
    {
      title: "Id",
      dataIndex: "documentId",
      key: "documentId",
      width: 90,
      fixed: "left",
    },
    {
      title: t("pages_expeditions.results.table.origin.label"),
      children: [
        {
          title: t(
            "pages_expeditions.results.table.origin.children.countryOrigin"
          ),
          dataIndex: "countryOrigin",
          key: "countryOrigin",
          width: 60,
        },
        {
          title: t(
            "pages_expeditions.results.table.origin.children.operatorOrigin"
          ),
          dataIndex: "operatorOrigin",
          key: "operatorOrigin",
          width: 60,
        },
        {
          title: t(
            "pages_expeditions.results.table.origin.children.officeOrigin"
          ),
          dataIndex: "officeOrigin",
          key: "officeOrigin",
          width: 60,
        },
      ],
    },
    {
      title: t("pages_expeditions.results.table.destination.label"),
      children: [
        {
          title: t(
            "pages_expeditions.results.table.destination.children.countryDestination"
          ),
          dataIndex: "countryDestination",
          key: "countryDestination",
          width: 60,
        },
        {
          title: t(
            "pages_expeditions.results.table.destination.children.operatorDestination"
          ),
          dataIndex: "operatorDestination",
          key: "operatorDestination",
          width: 60,
        },
        {
          title: t(
            "pages_expeditions.results.table.destination.children.officeDestination"
          ),
          dataIndex: "officeDestination",
          key: "officeDestination",
          width: 60,
        },
      ],
    },
    {
      title: t("pages_expeditions.results.table.airportCode.label"),
      dataIndex: "airportCode",
      key: "airportCode",
      width: 80,
    },
    {
      title: t("pages_expeditions.results.table.expeditionCode.label"),
      dataIndex: "expeditionCode",
      key: "expeditionCode",
      width: 80,
    },
    {
      title: t("pages_expeditions.results.table.flightStatus.label"),
      dataIndex: "flightStatus",
      key: "flightStatus",
      width: 80,
    },
    {
      title: t("pages_expeditions.results.table.transportNumber.label"),
      dataIndex: "transportNumber",
      key: "transportNumber",
      width: 80,
    },
    {
      title: t("pages_expeditions.results.table.departureDate.label"),
      dataIndex: "departureDate",
      key: "departureDate",
      width: 80,
      render: (text, row, index) => {
        return text?.length ? englishFormat(new Date(text)) : "";
      },
    },
    {
      title: t("pages_expeditions.results.table.expeditionDate.label"),
      dataIndex: "expeditionDate",
      key: "expeditionDate",
      width: 80,
      render: (text, row, index) => {
        return text?.length ? englishFormat(new Date(text)) : "";
      },
    },
  ];
};

export default columns;
