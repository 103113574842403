import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "antd";
import "./FilterCard.scss";

const FilterCard = (props) => {
  return (
    <Collapse defaultActiveKey={["1"]}>
      <Collapse.Panel key="1" header={props.header}>
        <div className="filter-card">
          {props.items?.map((item, index) => {
            return (
              <div key={index} className="filter-card__container">
                <span className="filter-card__container__title">
                  {item.title}
                </span>
                <div className="filter-card__fields">
                  {item.fields?.map((field, fIndex) => {
                    return (
                      <div key={fIndex} className="filter-card__field">
                        <span className="filter-card__field__title">
                          {field.title}
                        </span>
                        <span className="filter-card__field__value">
                          {field.value}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Collapse.Panel>
    </Collapse>
	);
};

FilterCard.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
  header: PropTypes.string
};

export default FilterCard;
