import React from "react";
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { 
    Button, 
    Card
} from 'antd';
import "./SearchCard.scss"

const SearchCard = (props) => {
    const history = useHistory();
    return (
        <Card 
            className="search-card"
        >
            {props.iconUrl?.length && <img src={props.iconUrl} alt="card icon"/>}
            <div className="search-card__title">{props.title}</div>
            <div className="search-card__description">{props.description}</div>
            <Button 
                type="link"
                onClick={() => history.push(`${props.route}`)}
            >
                {props.textButton} &gt;
            </Button>
        </Card>
    );
};

SearchCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    iconUrl: PropTypes.string,
    textButton: PropTypes.string,
    route: PropTypes.string
};

export default SearchCard;