import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Form as AntdForm,
    Row,
    Col,
    Input,
    Select,
    DatePicker,
    Button,
    Collapse,
    Icon,
    Divider,
} from "antd";
import style from "./Form.style";
import constants from "../../../../utils/constants";
import "./Form.scss";

import { storeArq } from 'correos-arq-utils';

const Form = (props) => {
    const { t } = props;
    const { getFieldDecorator } = props.form;

    const [destinationCarrierCode, setDestinationCarrierCode] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const criteria = {
                    ...values,
                    dispatchDate: values.dispatchDate?.format()
                };

                const query = {
                    offset: 1,
                    count: constants.itemsPerPage,
                    country: values.countryOrigin,
                    searchType: constants.officeSearch,
                    criteria
                };

                /*const fakeQuery = {
                  offset: 1,
                  count: constants.itemsPerPage,
                  country: "ES",
                  searchType:"despacho",
                  criteria:{
                      countryOrigin: "ES",
                      operatorOrigin: "ESA",
                      officeOrigin: "MADB",
                      countryDestination: "US",
                      operatorDestination: "ESA",
                      officeDestination: "MADC",
                      dispatchDate: "2020-03-08T18:47:04+01:00",
                      dispatchYear: "2020",
                      dispatchNumber: "0008",
                      dispatchType: "C",
                      documentQuery: "CN16",
                      category: "UH",
                      documentation: "incompleta",
                      operationType: "EXP"
                  }
                };*/

                props.onSubmit(query);
            }
        });
    };

    useEffect(() => {
        setDestinationCarrierCode(storeArq.data.auth.userInfo.rolReal[0].substring(storeArq.data.auth.userInfo.rolReal[0].length-3));
    }, []);

    return (
        <div className="form">
            <Row justify="center">
                <Col className="form__title" lg={25}>
                    {t("pages_offices.form.title")}
                </Col>
            </Row>
            <AntdForm onSubmit={handleSubmit} layout="vertical">
                <div className="form--shadow">
                    <Row className="form__container">
                        <Row>
                            <Col className="form__section--title" lg={9}>
                                {t("pages_offices.form.sectionOne")}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <p className="o-txt">
                                    {t("pages_offices.form.descriptionOne")}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <AntdForm.Item
                                    {...style.countryOrigin}
                                    label={t("pages_offices.form.countryOrigin.label")}
                                >
                                    {getFieldDecorator("countryOrigin", {
                                        initialValue: "ES",
                                        rules: [
                                            {
                                                required: false,
                                                message: t("pages_offices.form.countryOrigin.validation"),
                                            }
                                        ],
                                    })(
                                        <Input type="text" readOnly disabled
                                            placeholder={t(
                                                "pages_offices.form.countryOrigin.placeholder"
                                            )}
                                        />
                                    )}
                                </AntdForm.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <AntdForm.Item
                                    {...style.operatorOrigin}
                                    label={t("pages_offices.form.operatorOrigin.label")}
                                >
                                    {getFieldDecorator("operatorOrigin", {
                                        initialValue: "ESA",
                                        rules: [{ required: false }],
                                    })(
                                        <Input type="text" readOnly disabled
                                            placeholder={t(
                                                "pages_offices.form.countryOrigin.placeholder"
                                            )}
                                        />
                                    )}
                                </AntdForm.Item>
                            </Col>
                        </Row>
                        <Row>
                            <AntdForm.Item
                                {...style.officeOrigin}
                                label={t("pages_offices.form.officeOrigin.label")}
                                hasFeedback
                            >
                                {getFieldDecorator("officeOrigin", {
                                    rules: [
                                        {
                                            required: true,
                                            message: t("pages_offices.form.officeOrigin.validation"),
                                        },
                                    ],
                                })(
                                    <Select
                                        placeholder={t(
                                            "pages_offices.form.officeOrigin.placeholder"
                                        )}
                                    >
                                        {props?.sourceOffices?.map((office, index) => (
                                            <Select.Option key={index} value={office.value}>
                                                {office.description}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </AntdForm.Item>
                        </Row>
                        <Row>
                            <Col className="form__section form__section--title" lg={30}>
                                {t("pages_offices.form.sectionTwo")}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <p className="o-txt">
                                    {t("pages_offices.form.descriptionTwo")}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <AntdForm.Item
                                    {...style.countryDestination}
                                    label={t("pages_offices.form.countryDestination.label")}
                                >
                                    {getFieldDecorator("countryDestination", {
                                        rules: [{ required: false }],
                                    })(
                                        <Input
                                            placeholder={t(
                                                "pages_offices.form.countryDestination.placeholder"
                                            )}
                                        />
                                    )}
                                </AntdForm.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <AntdForm.Item
                                    {...style.operatorDestination}
                                    label={t("pages_offices.form.operatorDestination.label")}
                                >
                                    {getFieldDecorator("operatorDestination", {
                                        initialValue: destinationCarrierCode,
                                        rules: [{ required: false }],
                                    })(
                                        <Input readOnly disabled
                                            placeholder={t(
                                                "pages_offices.form.operatorDestination.placeholder"
                                            )}
                                        />
                                    )}
                                </AntdForm.Item>
                            </Col>
                        </Row>
                        <Row>
                            <AntdForm.Item
                                {...style.officeDestination}
                                label={t("pages_offices.form.officeDestination.label")}
                                hasFeedback
                            >
                                {getFieldDecorator("officeDestination", {
                                    rules: [{ required: false }],
                                })(
                                    <Input
                                        placeholder={t(
                                        "pages_offices.form.officeDestination.placeholder"
                                        )}
                                    />
                                )}
                            </AntdForm.Item>
                        </Row>
                        <Row>
                            <Col className="form__section form__section--title" lg={30}>
                                {t("pages_offices.form.sectionThree")}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={30}>
                                <p className="o-txt">
                                    {t("pages_offices.form.descriptionThree")}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <AntdForm.Item
                                    {...style.dispatchDate}
                                    label={t("pages_offices.form.dispatchDate.label")}
                                >
                                    {getFieldDecorator("dispatchDate", {
                                        rules: [
                                            {
                                                type: "object",
                                                required: true,
                                                message: t(
                                                    "pages_offices.form.dispatchDate.validation"
                                                ),
                                            },
                                        ],
                                    })(
                                        <DatePicker
                                            placeholder={t(
                                                "pages_offices.form.dispatchDate.placeholder"
                                            )}
                                            format="MM/DD/YYYY"
                                        />
                                    )}
                                </AntdForm.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Divider />
                            <Col className="form__disclaimer" lg={8}>
                                <Icon type="info-circle" />
                                {`  ${t("pages_offices.form.requiredDisclaimer")}`}
                            </Col>
                            <Divider />
                        </Row>
                    </Row>
                    <Collapse defaultActiveKey={["1"]}>
                        <Collapse.Panel
                            header={t("pages_offices.form.accordionText")}
                            key="1"
                        >
                            <Row>
                                <Col className="form__section--title" lg={30}>
                                    {t("pages_offices.form.sectionFour")}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={30}>
                                    <p className="o-txt">
                                        {t("pages_offices.form.descriptionFour")}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={30}>
                                    <AntdForm.Item
                                        {...style.dispatchYear}
                                        label={t("pages_offices.form.dispatchYear.label")}
                                    >
                                        {getFieldDecorator("dispatchYear", {
                                            rules: [{ required: false }],
                                        })(
                                            <Input
                                                placeholder={t(
                                                    "pages_offices.form.dispatchYear.placeholder"
                                                )}
                                            />
                                        )}
                                    </AntdForm.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={30}>
                                    <AntdForm.Item
                                        {...style.dispatchNumber}
                                        label={t("pages_offices.form.dispatchNumber.label")}
                                    >
                                        {getFieldDecorator("dispatchNumber", {
                                            rules: [{ required: false }],
                                        })(
                                            <Input
                                                placeholder={t(
                                                    "pages_offices.form.dispatchNumber.placeholder"
                                                )}
                                            />
                                        )}
                                    </AntdForm.Item>
                                </Col>
                            </Row>
                            <Row>
                                <AntdForm.Item
                                    {...style.dispatchType}
                                    label={t("pages_offices.form.dispatchType.label")}
                                >
                                    {getFieldDecorator("dispatchType", {
                                        rules: [{ required: false }],
                                    })(
                                        <Select
                                            placeholder={t(
                                                "pages_offices.form.dispatchType.placeholder"
                                            )}
                                        >
                                            {props?.dispatchValues?.map((dispatch, index) => (
                                                <Select.Option key={index} value={dispatch.value}>
                                                    {dispatch.description}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </AntdForm.Item>
                            </Row>
                            <Row>
                                <Col className="form__section form__section--title" lg={30}>
                                    {t("pages_offices.form.sectionFive")}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={30}>
                                    <p className="o-txt">
                                        {t("pages_offices.form.descriptionFive")}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <AntdForm.Item
                                    {...style.documentQuery}
                                    label={t("pages_offices.form.documentQuery.label")}
                                    hasFeedback
                                >
                                    {getFieldDecorator("documentQuery", {
                                        rules: [{ required: false }],
                                    })(
                                        <Select
                                            placeholder={t(
                                                "pages_offices.form.documentQuery.placeholder"
                                            )}
                                        >
                                            {props?.documents?.map((document, index) => (
                                                <Select.Option key={index} value={document.value}>
                                                    {document.description}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </AntdForm.Item>
                            </Row>
                            <Row>
                                <AntdForm.Item
                                    {...style.category}
                                    label={t("pages_offices.form.category.label")}
                                >
                                    {getFieldDecorator("category", {
                                        rules: [{ required: false }],
                                    })(
                                        <Select
                                            placeholder={t("pages_offices.form.category.placeholder")}
                                        >
                                            {props?.categories?.map((category, index) => (
                                                <Select.Option key={index} value={category.value}>
                                                    {category.description}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </AntdForm.Item>
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <Row className="form__submit">
                    <Col span={5}>
                        <AntdForm.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: "50 %" }}
                            >
                                {t("pages_offices.form.submit")}
                            </Button>
                        </AntdForm.Item>
                    </Col>
                </Row>
            </AntdForm>
        </div>
    );
};

Form.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    documents: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    sourceOffices: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    targetOffices: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    dispatchValues: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    onSubmit: PropTypes.func,
};

export default AntdForm.create()(Form);
