/** @typedef {Object} SearchOfficeDTO
 * @property {Number} offset
 * @property {Number} count
 * @property {String} country
 * @property {String} searchType - despacho
 * @property {Object} criteria
 * @property {String} criteria.category
 * @property {String} criteria.countryDestination
 * @property {String} criteria.countryOrigin
 * @property {String} criteria.dispatchDate
 * @property {String} criteria.dispatchNumber
 * @property {String} criteria.dispatchType
 * @property {String} criteria.dispatchYear
 * @property {String} criteria.documentQuery
 * @property {String} criteria.documentation
 * @property {String} criteria.officeDestination
 * @property {String} criteria.officeOrigin
 * @property {String} criteria.operationType
 * @property {String} criteria.operatorDestination
 * @property {String} criteria.operatorOrigin
 */

 /** @typedef {Object} SearchExpeditionDTO
 * @property {Number} offset
 * @property {Number} count
 * @property {String} country
 * @property {String} searchType - expedicion
 * @property {Object} criteria
 * @property {String} criteria.airportCode
 * @property {String} criteria.countryDestination
 * @property {String} criteria.countryOrigin
 * @property {String} criteria.departureDate
 * @property {String} criteria.documentQuery
 * @property {String} criteria.expeditionCode
 * @property {String} criteria.expeditionDate
 * @property {String} criteria.flightStatus
 * @property {String} criteria.officeDestination
 * @property {String} criteria.officeOrigin
 * @property {String} criteria.operationType
 * @property {String} criteria.operatorDestination
 * @property {String} criteria.operatorOrigin
 * @property {String} criteria.transportNumber
 */

/** @typedef {Object} SearchOfficesResponseDTO
 * @property {Object} pagination
 * @property {Number} pagination.currPage
 * @property {Number} pagination.itemsPage
 * @property {Boolean} pagination.moreItems
 * @property {Number} pagination.totalItems
 * @property {Number} pagination.totalPages
 * @property {Object[]} results
 * @property {String} results[].accessLevel
 * @property {String} results[].category
 * @property {String} results[].contentFileName
 * @property {String} results[].contentMimeType
 * @property {String} results[].countryDestination
 * @property {String} results[].countryOrigin
 * @property {String} results[].dispatchDate
 * @property {String} results[].dispatchNumber
 * @property {String} results[].dispatchType
 * @property {String} results[].dispatchYear
 * @property {String} results[].documentId
 * @property {String} results[].documentQuery
 * @property {String} results[].documentSubType
 * @property {String} results[].documentType
 * @property {String} results[].documentation
 * @property {String} results[].internalId
 * @property {Boolean} results[].locked
 * @property {String} results[].name
 * @property {String} results[].officeDestination
 * @property {String} results[].officeOrigin
 * @property {String} results[].operationtype
 * @property {String} results[].operatorDestination
 * @property {String} results[].operatorOrigin
 * @property {String} results[].responsibleUnit
 * @property {String} results[].state
 * @property {String} results[].version
 */

 /** @typedef {Object} SearchExpeditionsResponseDTO
 * @property {Object} pagination
 * @property {Number} pagination.currPage
 * @property {Number} pagination.itemsPage
 * @property {Boolean} pagination.moreItems
 * @property {Number} pagination.totalItems
 * @property {Number} pagination.totalPages
 * @property {Object[]} results
 * @property {String} results[].accessLevel
 * @property {String} results[].airportCode
 * @property {String} results[].contentFileName
 * @property {String} results[].contentMimeType
 * @property {String} results[].countryOrigin
 * @property {String} results[].departureDate
 * @property {String} results[].documentId
 * @property {String} results[].documentQuery
 * @property {String} results[].documentSubType
 * @property {String} results[].documentType
 * @property {String} results[].expeditionCode
 * @property {String} results[].expeditionDate
 * @property {String} results[].flightStatus
 * @property {String} results[].internalId
 * @property {Boolean} results[].locked
 * @property {String} results[].name
 * @property {String} results[].officeDestination
 * @property {String} results[].officeOrigin
 * @property {String} results[].operationtype
 * @property {String} results[].operatorDestination
 * @property {String} results[].operatorOrigin
 * @property {String} results[].responsibleUnit
 * @property {String} results[].state
 * @property {String} results[].transportNumber
 * @property {String} results[].version
 */

/** @typedef {Object} MasterdataResponseDTO
 * @property {Object} results
 * @property {String} results[].value
 * @property {String} results[].description
 */
 

export const searchActionKeys = {
    searchDocuments: 'searchDocuments',
    searchDocumentsSuccess: 'searchDocuments success',
    searchDocumentsFailure: 'searchDocuments failure',
    searchMasterData: 'searchMasterData',
    searchMasterDataSuccess: 'searchMasterData success',
    searchMasterDataFailure: 'searchMasterData failure',
    downloadContent: 'downloadContent',
    downloadContentSuccess: 'downloadContent success',
    downloadContentFailure: 'downloadContent failure',
    newSearch: 'newSearch'
};

export const masterdataKeys = {
    categories: 'categories',
    officeDocuments: 'officeDocuments',
    expeditionDocuments: 'expeditionDocuments',
    sourceOffices: 'sourceOffices',
    targetOffices: 'targetOffices',
    dispatchValues: 'dispatchValues',
}

export const initialState = {
    isLoading: false,
    error: false,
    [masterdataKeys.categories]: [],
    [masterdataKeys.officeDocuments]: [],
    [masterdataKeys.expeditionDocuments]: [],
    [masterdataKeys.sourceOffices]: [],
    [masterdataKeys.targetOffices]: [],
    items: [],
    query: undefined,
    pagination: {
        currentPage: 0,
        totalItems: 0,
        totalPages: 0
    },
    showResults: false
}

export const searchActions = {

    /** START: Search Actions */
    /**
     * @param {SearchOfficeDTO|SearchExpeditionDTO} query
     */
    [searchActionKeys.searchDocuments]: (query) => ({
        type: searchActionKeys.searchDocuments,
        query
    }),

    [searchActionKeys.searchDocumentsSuccess]: (payload) => ({
        type: searchActionKeys.searchDocumentsSuccess,
        payload
    }),

    [searchActionKeys.searchDocumentsFailure]: (error) => ({
        type: searchActionKeys.searchDocumentsFailure
    }),

    [searchActionKeys.newSearch]: () => ({
        type: searchActionKeys.newSearch
    }),
    /** END: Search Actions */

    /** START: Masterdata Actions */
    [searchActionKeys.searchMasterData]: () => ({
        type: searchActionKeys.searchMasterData
    }),

    /**
     * @param {Object.<string, {value: string, description: string}>} payload
     */
    [searchActionKeys.searchMasterDataSuccess]: (payload) => ({
        type: searchActionKeys.searchMasterDataSuccess,
        payload
    }),

    [searchActionKeys.searchMasterDataFailure]: (error) => ({
        type: searchActionKeys.searchMasterDataFailure
    }),
    /** END: Masterdata Actions */

    /** START: Download Actions */
    [searchActionKeys.downloadContent]: () => ({
        type: searchActionKeys.downloadContent
    }),

    [searchActionKeys.downloadContentSuccess]: () => ({
        type: searchActionKeys.downloadContentSuccess
    }),

    [searchActionKeys.downloadContentFailure]: () => ({
        type: searchActionKeys.downloadContentFailure
    }),
    /** END: Download Actions */
}

export const searchReducer = (state, action) => {
    switch (action.type) {
        case searchActionKeys.searchDocuments:
            return {
                ...state,
                isLoading: true,
                query: action?.query,
                showResults: true
            };
        case searchActionKeys.searchDocumentsSuccess:
            return {
                ...state,
                isLoading: false,
                items: action?.payload?.results,
                pagination: {
                    currentPage: action?.payload?.pagination?.currPage,
                    totalItems: action?.payload?.pagination?.totalItems,
                    totalPages: action?.payload?.pagination?.totalPages
                }
            };
        case searchActionKeys.searchDocumentsFailure:
            return {
                ...state,
                isLoading: false,
                error: true
            };
        case searchActionKeys.searchMasterData:
            return {
                ...state,
                isLoading: true,
            };
        case searchActionKeys.searchMasterDataSuccess:
            return {
                ...state,
                isLoading: false,
                [masterdataKeys.categories]: action?.payload[masterdataKeys.categories],
                [masterdataKeys.officeDocuments]: action?.payload[masterdataKeys.officeDocuments],
                [masterdataKeys.expeditionDocuments]: action?.payload[masterdataKeys.expeditionDocuments],
                [masterdataKeys.sourceOffices]: action?.payload[masterdataKeys.sourceOffices],
                [masterdataKeys.targetOffices]: action?.payload[masterdataKeys.targetOffices],
                [masterdataKeys.dispatchValues]: action?.payload[masterdataKeys.dispatchValues],
            };
        case searchActionKeys.searchMasterDataFailure:
            return {
                ...state,
                isLoading: false,
                error: true
            };
        case searchActionKeys.downloadContent:
            return {
                ...state,
                isLoading: true,
            };
        case searchActionKeys.downloadContentSuccess:
            return {
                ...state,
                isLoading: false
            };
        case searchActionKeys.downloadContentFailure:
            return {
                ...state,
                isLoading: false,
                error: true
            };
        case searchActionKeys.newSearch:
            return {
                ...state,
                isLoading: false,
                error: true,
                showResults: false,
                items: [],
                query: undefined,
                pagination: {
                    currentPage: 0,
                    totalItems: 0,
                    totalPages: 0
                },
            };
        default:
            return state;
    }
}