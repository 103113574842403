import React, { useEffect, useReducer } from "react";
import { withTranslation } from "react-i18next";
import Form from "./components/form/Form";
import Results from "./components/results/Results";
import {
    searchReducer,
    initialState,
    searchActionKeys,
    searchActions,
    masterdataKeys,
} from "../../hooks/search";
import { masterdataTypes } from "../../models/search";
import {
    searchMasterData,
    searchDocuments,
    downloadContent,
} from "../../Services/searchService";
import { Skeleton, Pagination } from "antd";
import { savePdf } from "../../utils/helpers";
import { englishFormat } from "../../utils/dateFormatters";
import constants from "../../utils/constants";
import "./Offices.scss";

/**
 * @typedef {import('../../hooks/search.js').SearchOfficeDTO} SearchOfficeDTO
 */

const Offices = (props) => {
    const { t } = props;
    const [state, dispatch] = useReducer(searchReducer, initialState);

    const newSearch = () => {
        dispatch(searchActions[searchActionKeys.newSearch]());
    };

    /**
     * @param {SearchOfficeDTO} query
     */
    const onSearch = (query) => {
        dispatch(searchActions[searchActionKeys.searchDocuments](query));

        searchDocuments(query)
            .then((result) => {
                dispatch(
                    searchActions[searchActionKeys.searchDocumentsSuccess](result.data)
                );
            })
            .catch((error) => {
                dispatch(searchActions[searchActionKeys.searchDocumentsFailure](error));
            });
    };

    /**
     * @param {string} documentId - Document identifier returned by the search
     * @param {Object.<string, number>} params - Query params
     */
    const onDownload = (documentId, params) => {
        dispatch(searchActions[searchActionKeys.downloadContent]());

        downloadContent(documentId, params)
            .then((result) => {
                savePdf(result.data, params.name);
                dispatch(searchActions[searchActionKeys.downloadContentSuccess]());
            })
            .catch((error) => {
                dispatch(searchActions[searchActionKeys.downloadContentFailure](error));
            });
    };

    /**
     * @param {nume} page - current page number
     */
    const onPageChange = (page) => {

        const customOffset = page === 1
            ? 1
            : 1 + (constants.itemsPerPage * (page - 1));

        const query = {
            ...state.query,
            offset: customOffset,
        };

        onSearch(query);
    };

    const setCustomStyle = () => {
        document.getElementById("breadcrumb").classList.remove("breadcrumb--white");
        document.getElementById("breadcrumb").classList.add("breadcrumb--black");
    };

    const getCurrentFilters = () => {
        return [
            {
                title: t("pages_offices.filters.origin.label"),
                fields: [
                    {
                        title: t("pages_offices.filters.origin.children.countryOrigin"),
                        value: state.query?.criteria?.countryOrigin || "",
                    },
                    {
                        title: t("pages_offices.filters.origin.children.operatorOrigin"),
                        value: state.query?.criteria?.operatorOrigin || "",
                    },
                    {
                        title: t("pages_offices.filters.origin.children.officeOrigin"),
                        value: state.query?.criteria?.officeOrigin || "",
                    },
                ],
            },
            {
                title: t("pages_offices.filters.destination.label"),
                fields: [
                    {
                        title: t(
                            "pages_offices.filters.destination.children.countryDestination"
                        ),
                        value: state.query?.criteria?.countryDestination || "",
                    },
                    {
                        title: t(
                            "pages_offices.filters.destination.children.operatorDestination"
                        ),
                        value: state.query?.criteria?.operatorDestination || "",
                    },
                    {
                        title: t(
                            "pages_offices.filters.destination.children.officeDestination"
                        ),
                        value: state.query?.criteria?.officeDestination || "",
                    },
                ],
            },
            {
                title: t("pages_offices.filters.dispatch.label"),
                fields: [
                    {
                        title: t("pages_offices.filters.dispatch.children.dispatchDate"),
                        value: state.query?.criteria?.dispatchDate?.length
                            ? englishFormat(new Date(state.query?.criteria?.dispatchDate))
                            : "",
                    },
                    {
                        title: t("pages_offices.filters.dispatch.children.dispatchYear"),
                        value: state.query?.criteria?.dispatchYear || "",
                    },
                    {
                        title: t("pages_offices.filters.dispatch.children.dispatchNumber"),
                        value: state.query?.criteria?.dispatchNumber || "",
                    },
                ],
            },
            {
                title: t("pages_offices.filters.others.label"),
                fields: [
                    {
                        title: t("pages_offices.filters.others.children.category"),
                        value: state.query?.criteria?.category || "",
                    },
                    {
                        title: t("pages_offices.filters.others.children.documentQuery"),
                        value: state.query?.criteria?.documentQuery || "",
                    },
                ],
            },
        ];
    };

    /**
     * Load all the form masterdata
     */
    useEffect(() => {
        setCustomStyle();
        dispatch(searchActions[searchActionKeys.searchMasterData]());

        const promises = [
            searchMasterData(masterdataTypes.category),
            searchMasterData(masterdataTypes.officeDocument),
            searchMasterData(masterdataTypes.sourceOffice),
            searchMasterData(masterdataTypes.targetOffice),
            searchMasterData(masterdataTypes.dispatch),
        ];

        Promise.all(promises)
            .then((values) => {
                const response = {
                    [masterdataKeys.categories]: values[0].data?.results || [],
                    [masterdataKeys.officeDocuments]: values[1].data?.results || [],
                    [masterdataKeys.sourceOffices]: values[2].data?.results || [],
                    [masterdataKeys.targetOffices]: values[3].data?.results || [],
                    [masterdataKeys.dispatchValues]: values[4].data?.results || [],
                };

                dispatch(
                    searchActions[searchActionKeys.searchMasterDataSuccess](response)
                );
            })
            .catch((error) => {
                dispatch(
                    searchActions[searchActionKeys.searchMasterDataFailure](error)
                );
            });
    }, []);

    return (
        <>
            {!state.showResults ? (
                <Skeleton loading={state.isLoading}>
                    <Form
                        {...props}
                        categories={state[masterdataKeys.categories]}
                        documents={state[masterdataKeys.officeDocuments]}
                        sourceOffices={state[masterdataKeys.sourceOffices]}
                        targetOffices={state[masterdataKeys.targetOffices]}
                        dispatchValues={state[masterdataKeys.dispatchValues]}
                        onSubmit={onSearch}

                    />
                </Skeleton>
            ) : (
                <>
                    <div className="results">
                        <Results
                            {...props}
                            items={state.items}
                            onDownload={onDownload}
                            onNewSearch={newSearch}
                            filters={getCurrentFilters()}
                            totalItems={state.pagination?.totalItems}
                            isLoading={state.isLoading}
                        />
                    </div>

                    {state.pagination?.totalItems > 0 &&
                        (<div className="pagination">
                            <Pagination
                                onChange={onPageChange}
                                pageSize={constants.itemsPerPage}
                                total={state.pagination?.totalItems}
                            />
                        </div>)
                    }
                </>
            )}
        </>
    );
};

export default withTranslation()(Offices);
