/**
 * 
 * @param {Date} d Date
 * @returns /MM/DD/YYYY
 */
function englishFormat(d) {
  return new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
  }).format(d);
}

export {
  englishFormat
};
