import { instance } from '../Api/Instance';
import { serviceKeys, serviceLocator, credencialesAPI } from '../Api/ApiUrl';


/**
 * @typedef {import('../hooks/search.js').SearchOfficeDTO} SearchOfficeDTO
 * @typedef {import('../hooks/search.js').SearchExpeditionDTO} SearchExpeditionDTO
 * @typedef {import('../hooks/search.js').SearchOfficesResponseDTO} SearchOfficesResponseDTO
 * @typedef {import('../hooks/search.js').SearchExpeditionsResponseDTO} SearchExpeditionsResponseDTO
 * @typedef {import('../hooks/search.js').MasterdataResponseDTO} MasterdataResponseDTO
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

const headers = {
    'client_id': credencialesAPI.clientId,
    'client_secret': credencialesAPI.clientSecret
}

/**
 * @param {SearchOfficeDTO|SearchExpeditionDTO} body 
 * @returns {Promise.<AxiosResponse.<SearchOfficesResponseDTO>>|Promise.<AxiosResponse.<SearchExpeditionsResponseDTO>>} - Response
 */
export async function searchDocuments(body) {
    const url = `${window._env_.REACT_APP_API_HOST}${serviceLocator[serviceKeys.searchDocuments].path}`;
    return instance.post(url, body, {
        headers
    });
}

/**
 * @param {string} type - CATEGORIA | DOCUMENTO_CONSULTA | OFICINA_ORIGEN | OFICINA_DESTINO 
 * @returns {Promise.<AxiosResponse.<MasterdataResponseDTO>>}
 */
export async function searchMasterData(type) {
    const url = `${window._env_.REACT_APP_API_HOST}${serviceLocator[serviceKeys.searchMasterData].path}`;

    return instance.get(url, {
        headers,
        params: { type }
    });
}

/**
 * @param {string} documentId - Document identifier returned by the search
 * @param {Object.<string, number>} params - Query params
 */
export async function downloadContent(documentId, params) {
    let url = `${window._env_.REACT_APP_API_HOST}${serviceLocator[serviceKeys.downloadContent].path}`;
    url = url.replace(RegExp(`{documentId}`, 'g'), documentId);

    return instance.get(url, {
        responseType: 'blob',
        headers,
        params
    });
}
