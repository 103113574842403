import { Icon, Modal, Spin } from "antd";
import React from "react";

const Loader = props => {
    const { t } = props;
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    return (
        <Modal
            visible
            centered
            closable={false}
            footer={null}
        >
            <div className="paperReloj">
                <Spin indicator={antIcon} tip="Loading..." />
            </div>
        </Modal>
    )
};

export default Loader